import App from "next/app"
import Head from "next/head"
import ErrorPage from "next/error"
import { DefaultSeo } from "next-seo"
import { getStrapiMedia } from "../utils/media"
import { getGlobalData } from "../utils/api"
import "../styles/index.css"
import { MobileMenuProvider } from "../components/context/mobile-menu-context"
//import { ReCaptchaProvider } from "next-recaptcha-v3"
//import { ErrorBoundary } from "./error-boundary"
import Layout from "../components/layout"
import { GoogleTagManager } from '@next/third-parties/google'

const MyApp = ({ Component, pageProps }) => {
  if (pageProps.global?.attributes == undefined) {
    //console.log('no globals - emitting 404')
    return <ErrorPage statusCode={404} />
  }

  const global = pageProps.global
  const getLayout = Component.getLayout || ((page) => page)

  const { metadata, favicon, metaTitleSuffix } = global.attributes

  return (
    // <ErrorBoundary>
    <>

      {/* Favicon */}
      <Head>
        <link rel="dns-prefetch" href="https://cloudfront.net" />
        <link rel="dns-prefetch" href="https://helpfulcrowd.com" />
        <link rel="dns-prefetch" href="https://storage.googlapis.com" />
        <link rel="preconnect" href="https://fonts.gstatic.com" />
        {/* <link rel="dns-prefetch" href="https://app.ecwid.com" /> */}

        {(favicon.data !== null) ? <link rel="shortcut icon" href={getStrapiMedia(favicon.data.attributes.url)} /> : <></>}
        {/* Google Shopping Site Verification */}
        <meta name="google-site-verification" content="SA2TajKPrkd-1duhR8sYpNU5h6wS9wk1wF10tY003bw" />
      </Head>
      {/* Global site metadata */}
      {(metadata != null) ? <DefaultSeo
        titleTemplate={`%s | ${metaTitleSuffix}`}
        title="Page"
        description={metadata.metaDescription}
        openGraph={{
          // Title and description are mandatory
          title: metadata.metaTitle,
          description: metadata.metaDescription,
          // Only include OG image if we have it
          // Careful: if you disable image optimization in Strapi, this will break
          ...(metadata.shareImage &&
            metadata.shareImage.data && {
            images: Object.values(
              metadata.shareImage.data.attributes.formats || []
            ).map((image) => {
              return {
                url: getStrapiMedia(image.url),
                width: image.width,
                height: image.height,
              }
            }),
          }),
        }}
        // Only included Twitter data if we have it
        twitter={{
          ...(metadata.twitterCardType && {
            cardType: metadata.twitterCardType,
          }),
          // Handle is the twitter username of the content creator
          ...(metadata.twitterUsername && { handle: metadata.twitterUsername }),
          ...(metadata.twitterUsername && { handle: metadata.twitterUsername }),
        }}
      /> : <></>}
      {/* Display the content */}
      {/* <ReCaptchaProvider useEnterprise language="en"> */}

        <MobileMenuProvider>

            {/* This version uses ONLY per-page layouts */}
            {/* {getLayout(<Component {...pageProps} />)} */}
            <Layout global={global}>
              <Component {...pageProps} />
              <GoogleTagManager gtmId="GTM-T7XSCJCC" />
              {/* gtmId="GT-MR4HZCPS"/> */}
            </Layout>
        </MobileMenuProvider>

      {/* </ReCaptchaProvider> */}

      {/* </ErrorBoundary> */}
    </>
  )
}
//gtag('config', 'G-33PWC2PDPM');
// /GT-MR4HZCPS

MyApp.getInitialProps = async (appContext) => {
  const appProps = await App.getInitialProps(appContext)
  // const globalLocale = await getGlobalData(appContext.router.locale)
  //console.log("Application Props =>")
  //console.log(appProps)

  const globalLocale = await getGlobalData('en')
  console.log(globalLocale)

  return {
    ...appProps,
    pageProps: {
      global: globalLocale,
    },
  }
}

export default MyApp